import { Box, useMediaQuery, useStyleConfig } from "@chakra-ui/react";
import Image from "next/image";
import React from "react";

import { H3 } from "@components/atoms/Text/H3";
import { breakpoints } from "@styles/constants";

import BlackTieImg from "images/occasions/quiz-occasion-black-tie-desktop.jpg";
import BlackTieMobileImg from "images/occasions/quiz-occasion-black-tie-mobile.jpg";
import CocktailImg from "images/occasions/quiz-occasion-cocktail-party-desktop.jpg";
import CocktailMobileImg from "images/occasions/quiz-occasion-cocktail-party-mobile.jpg";
import BottomlessBrunchImg from "images/occasions/quiz-occasion-daytime-desktop.jpg";
import BottomlessBrunchMobileImg from "images/occasions/quiz-occasion-daytime-mobile.jpg";
import FormalsImg from "images/occasions/quiz-occasion-formal-desktop.jpg";
import FormalsMobileImg from "images/occasions/quiz-occasion-formal-mobile.jpg";
import WeddingGuestImg from "images/occasions/quiz-occasion-garden-wedding-desktop.jpg";
import WeddingGuestMobileImg from "images/occasions/quiz-occasion-garden-wedding-mobile.jpg";
import PartyImg from "images/occasions/quiz-occasion-party-desktop.jpg";
import PartyMobileImg from "images/occasions/quiz-occasion-party-mobile.jpg";

const occasionOptions = [
  {
    id: "garden-wedding",
    name: "WEDDING GUEST",
    image: WeddingGuestImg,
    imageMobile: WeddingGuestMobileImg,
  },
  {
    id: "daytime-event",
    name: "BRUNCH",
    image: BottomlessBrunchImg,
    imageMobile: BottomlessBrunchMobileImg,
  },
  {
    id: "formal",
    name: "FORMALS",
    image: FormalsImg,
    imageMobile: FormalsMobileImg,
  },
  {
    id: "black-tie",
    name: "BLACK TIE",
    image: BlackTieImg,
    imageMobile: BlackTieMobileImg,
  },
  {
    id: "cocktail",
    name: "COCKTAIL",
    image: CocktailImg,
    imageMobile: CocktailMobileImg,
  },
  {
    id: "party",
    name: "PARTY",
    image: PartyImg,
    imageMobile: PartyMobileImg,
  },
];

const SelectOccasion: React.FC = ({ children, occasion, setOccasion }) => {
  const headerStyles = useStyleConfig("h3");
  const [isMobile] = useMediaQuery(`(max-width: ${breakpoints.md})`);

  return (
    <>
      <Box mx="auto" mt="1rem" textAlign="center">
        <Box __css={headerStyles} mt="1rem">
          WHAT&apos;S THE OCCASION?
        </Box>
        <Box px={["0rem", null, null, "3rem"]} mt="1rem">
          Whether you’re getting dressed for a special day, or everyday we’ve
          got you covered.
        </Box>
        <Box
          mt="1rem"
          display="flex"
          justifyContent="center"
          flexDirection="row"
          color="white"
          flexWrap="wrap"
        >
          {occasionOptions?.map(({ name, image, imageMobile, id }) => (
            <Box
              key={id}
              w={["9.5rem", null, null, "15rem"]}
              h={["6rem", null, null, "7rem"]}
              position="relative"
              mx="0.5rem"
              borderRadius="5px"
              display="flex"
              cursor="pointer"
              my="1rem"
              border={
                occasion === id ? "3px dashed #4158FF" : "1px dashed gray"
              }
              onClick={() => setOccasion(id)}
            >
              <Image
                src={isMobile ? imageMobile : image}
                width={270}
                height={130}
                alt={name}
                objectFit="cover"
              />
              <H3
                position="absolute"
                bottom="0"
                left="0.8rem"
                fontSize={["0.9rem", null, null, "1.1rem"]}
              >
                {name}
              </H3>
            </Box>
          ))}
        </Box>
      </Box>
      {children}
    </>
  );
};

export default SelectOccasion;
