import { Box, useStyleConfig } from "@chakra-ui/react";
import React, { ReactNode } from "react";

import useRedirectToDashboard from "@hooks/useRedirectToDashboard";

interface SelectLookingForProps {
  handleNextStep: Function;
  children: ReactNode;
}

const SelectLookingFor: React.FC<SelectLookingForProps> = ({
  handleNextStep,
  children,
}) => {
  const headerStyles = useStyleConfig("h3");
  const { redirectToDashboard } = useRedirectToDashboard();

  return (
    <>
      <Box mx="auto" mt="1rem" textAlign="center" color="white">
        <Box __css={headerStyles} mt="1rem">
          WHAT ARE YOU LOOKING FOR?
        </Box>
        <Box px={["0rem", null, null, "3rem"]} mt="1rem">
          Wear something new every weekend without breaking the bank, or make
          money from the high end pieces you already have. Let us know what
          you’re here for.
        </Box>
        <Box
          mt={["1rem", null, null, "1rem"]}
          display="flex"
          justifyContent="center"
          flexDirection="column"
        >
          <Box
            border="1.5px dashed #FFFFFF"
            h={["8rem", null, null, "10rem"]}
            mt="2rem"
            display="flex"
            alignItems="center"
            justifyContent="center"
            onClick={handleNextStep}
            cursor="pointer"
          >
            I want to rent designer fashion
          </Box>
          <Box
            border="1.5px dashed #FFFFFF"
            h={["8rem", null, null, "10rem"]}
            mt="2rem"
            display="flex"
            alignItems="center"
            justifyContent="center"
            onClick={redirectToDashboard}
            cursor="pointer"
          >
            I want to lend my designer wardrobe
          </Box>
        </Box>
      </Box>
      {children}
    </>
  );
};

export default SelectLookingFor;
